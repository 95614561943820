/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

/* angular materials */
@import "~@angular/material/prebuilt-themes/indigo-pink.css";

.divider-title {
    --padding-start: 0;
    box-shadow: 5px 15px 20px grey;
    position: fixed;
    border-radius: 0 0 100px 100px;
}

.title-page {
    text-align: center;
    height: 35px;
    color: black;
    font-size: 20px;
    font-weight: 500;
}

.container-principal div:first-child{
    // padding-top: 50px;
    height: 100%;
}

.no-scroll {
    overflow: hidden;
}

.scroll {
    overflow: scroll;
    padding-top: 0 !important;
    padding-bottom: 50px;
    height: calc(100% - 50px);
}

// menu action

.action-sheet-group {
    background-image: radial-gradient(circle at 50% 50%, #ffffff 0, #ffffff 25%, #fbfaf5 50%, #d4d2c0 75%, #b1b093 100%) !important;
}

.action-sheet-title.sc-ion-action-sheet-md {
    font-size: 20px;
    font-weight: bold;
    border-bottom: 1px solid black;
    padding-top: 6px;
    padding-bottom: 6px;
    min-height: 25px;
}

.action-sheet-button.sc-ion-action-sheet-md {
    box-shadow: 0px 2px 7px;
}

.alert-wrapper.sc-ion-alert-md {
    // background-image: radial-gradient(circle at 50% 50%, #888c95 0, #7b7c80 16.67%, #696966 33.33%, #535349 50%, #3c3f2e 66.67%, #292e19 83.33%, #192206 100%);
    border-radius: 15px;
}

.alert-title.sc-ion-alert-md {
    //color: B;
}

button.alert-button.alert-button-confirm {
    color: green;
    font-size: 20px;
}

button.alert-button.alert-button-cancel {
    color: red;
    font-size: 20px;
}


.mobile-page {
    app-cita,
    app-tarjeta,
    app-resumen,
    app-bono {
        width: 40% !important;
        display: inherit;
        margin-left: 30%;
    }
}

.mobile-page-no-inherit {
    app-tarjeta {
        width: 40% !important;
        margin-left: 30%;
        display: block;
    }
}